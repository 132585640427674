import React, {
  useEffect, useMemo, useState, memo, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { MerchantActionCreators } from '../../redux/actions/merchant';

const EMAIL_PATTERN = /\S+@\S+\.\S+/;

const {
  createStripeSubscription: createStripeSubscriptionAction,
  retrieveStripeProductPlan: retrieveStripeProductPlanAction,
} = MerchantActionCreators;

const options = {
  style: {
    hidePostalCode: true,
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#DC143C',
      iconColor: '#DC143C',
    },
  },
};

const PaymentForm = ({ merchantId, stripePlanId }) => {
  const [email, setEmail] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const {
    customerSubscriptionLoading,
    customerSubscriptionError,
    customerSubscriptionSuccess,
    stripePlan,
    stripeProduct,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */state) => state.MerchantReducer,
  );

  const dispatch = useDispatch();
  const [errorToDisplay, setErrorToDisplay] = useState('');
  const [subscribing, setSubscribing] = useState(false);

  useEffect(() => {
    setErrorToDisplay(customerSubscriptionError);
  }, [customerSubscriptionError]);

  useEffect(() => {
    setSubscribing(customerSubscriptionLoading);
  }, [customerSubscriptionLoading]);

  useEffect(() => {
    if (customerSubscriptionSuccess && elements) {
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        throw new Error('cardElement was not found');
      }

      setEmail('');
      cardElement.clear();
    }
  }, [customerSubscriptionSuccess, elements]);

  useEffect(() => {
    dispatch(retrieveStripeProductPlanAction(stripePlanId));
  }, [dispatch, stripePlanId]);

  const onEmailChange = useCallback((evt) => setEmail(evt.target.value), []);

  const isEmailAddress = useMemo(() => EMAIL_PATTERN.test(email), [email]);

  const stripePlanAmount = useMemo(() => {
    if (stripePlan && stripePlan.tiers && stripePlan.tiers.length > 0) {
      return stripePlan.tiers[0].flat_amount;
    }
    return 0;
  }, [stripePlan]);

  const stripePlanCurrency = useMemo(
    () => (stripePlan ? stripePlan.currency : ''),
    [stripePlan],
  );

  const stripeProductName = useMemo(() => stripeProduct.name, [
    stripeProduct.name,
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setSubscribing(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      throw new Error('cardElement was not found');
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error || !paymentMethod) {
      console.error('createPaymentMethod error', error);
      setSubscribing(false);
      setErrorToDisplay(error?.message || 'An unknown error occurred');
      return;
    }

    dispatch(
      createStripeSubscriptionAction(
        merchantId,
        stripePlanId,
        paymentMethod.id,
        email,
      ),
    );
  };

  const buttonText = useMemo(() => {
    if (customerSubscriptionSuccess) {
      return 'Subscribed!';
    }
    if (subscribing) {
      return 'Subscribing...';
    }
    return 'Subscribe';
  }, [customerSubscriptionSuccess, subscribing]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form">
        <div className="sr-combo-inputs">
          <div className="sr-combo-inputs-row">
            <input
              required
              type="email"
              id="email"
              name="email"
              placeholder="sarah.jane@company.com"
              autoComplete="cardholder"
              className="sr-input"
              onChange={onEmailChange}
              value={email}
            />
          </div>

          <div className="sr-combo-inputs-row">
            <CardElement
              className="sr-input sr-card-element"
              options={options}
            />
            <div className="sr-field-error">
              {errorToDisplay || null}
            </div>
          </div>
        </div>
        <div className="btn-container">
          <button
            className={`sub-btn${customerSubscriptionSuccess ? ' success' : ''}`}
            type="submit"
            disabled={!stripe || !isEmailAddress || customerSubscriptionSuccess}
          >
            {buttonText}
          </button>
          {stripeProductName && (
            <div>
              <h6>
                {`${stripeProductName} (${stripePlanAmount / 100} ${stripePlanCurrency.toUpperCase()})`}
              </h6>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default memo(PaymentForm);
